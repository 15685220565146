import { Typography } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";

export const Paragraph = ({ className, children, issp = false }) => {
  return (
    <Text className={className} issp={issp}>
      {children}
    </Text>
  );
};

const Text = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.black.primary};
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    line-height: 32px;
    margin-bottom: ${theme.fonts.size.base};
  `}
`;

export default Paragraph;
