import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import logo from "../images/logo.png";
import ogpThumbnail from "../images/ogp.png";

const ArticleJsonLd = ({ title, description, featuredImage, date, update }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const { siteUrl, defaultImage, author, defaultDescription } =
    site.siteMetadata;

  const jsonLdStructuredData = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": siteUrl + "/area/",
    },
    author: {
      "@type": "Person",
      name: author,
      url: siteUrl,
      image: `${siteUrl}${ogpThumbnail}`,
    },
    publisher: {
      "@type": "Organization",
      name: author,
      url: siteUrl,
      logo: {
        "@type": "ImageObject",
        url: `${siteUrl}${logo}`,
        width: 300,
        height: 300,
      },
    },
    headline: title,
    image: featuredImage ? featuredImage : defaultImage,
    url: `${siteUrl}${pathname}`,
    description: description || defaultDescription,
    datePublished: update,
    dateCreated: update,
    dateModified: update,
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jsonLdStructuredData)}
      </script>
    </Helmet>
  );
};

export default ArticleJsonLd;

const query = graphql`
  query Metadata {
    site {
      siteMetadata {
        siteUrl
        defaultImage
      }
    }
  }
`;
