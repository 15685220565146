import React from "react";
import styled, { css } from "styled-components";

export const Li = ({ className, children, issp = false }) => {
  return (
    <Text className={className} issp={issp}>
      {children}
    </Text>
  );
};

const Text = styled.li`
  ${({ theme }) => css`
    p {
      line-height: ${theme.fonts.size.xl2};
    }
  `}
`;

export default Li;
