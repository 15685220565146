import { graphql } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useState } from "react";
import ArticleJsonLd from "../components/articleJsonLd";
import LoadingCircular from "../components/loadingCircular";
import { default as Seo } from "../layouts/head";
import Layout from "../layouts/pc";
import { default as LayoutSP } from "../layouts/sp";
import { default as ArticlePC } from "./pc/article";
import ArticleSP from "./sp/article";

const Article = ({ pageContext, data }) => {
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(true);
  const { post } = pageContext;
  const title = `${post.title} | ロートリ 地方の面白いビジネスモデルを紹介`;
  useEffect(() => {
    setTimeout(() => setLoading(false), 100);
  });

  return (
    <>
      <Seo title={title} />
      <ArticleJsonLd
        title={title}
        date={post.createdAt}
        update={post.updatedAt}
      />
      {loading ? (
        <LoadingCircular />
      ) : breakpoints.sm ? (
        <LayoutSP>
          <ArticleSP
            content={post}
            newServiceContents={data.newServiceContents}
            similarityServiceContents={data.similarityServiceContents}
          />
        </LayoutSP>
      ) : (
        <Layout>
          <ArticlePC
            content={post}
            newServiceContents={data.newServiceContents}
            similarityServiceContents={data.similarityServiceContents}
          />
        </Layout>
      )}
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    newServiceContents: allContentfulServiceIndex(
      sort: { order: DESC, fields: createdAt }
      filter: { id: { ne: $id } }
      limit: 3
    ) {
      ...contentsData
    }
    similarityServiceContents: allContentfulServiceIndex(
      sort: { order: DESC, fields: createdAt }
      filter: { id: { ne: $id } }
      limit: 2
    ) {
      ...contentsData
    }
  }
`;

export default Article;
