import { GatsbyImage as BaseGatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

export const Image = ({ image, alt }) => {
  return <GatsbyImage image={image} alt={alt} />;
};

const GatsbyImage = styled(BaseGatsbyImage)`
  margin-bottom: 15px;
`;

export default Image;
