import { Typography } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";

const Heading1 = ({ className, children, issp = false }) => {
  return (
    <Div>
      <DottedLine>
        <Text className={className} issp={issp}>
          {children}
        </Text>
      </DottedLine>
    </Div>
  );
};

const Div = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.orange.primary};
    padding: 5px;
    margin-bottom: ${theme.fonts.size.base};
    border-radius: 10px;
  `}
`;

const DottedLine = styled.div`
  ${({ theme }) => css`
    border-radius: 10px;
    background-image: linear-gradient(
        to right,
        ${theme.colors.white.primary},
        ${theme.colors.white.primary} 3px,
        transparent 3px,
        transparent 8px
      ),
      /* 上の線 */
        linear-gradient(
          to bottom,
          ${theme.colors.white.primary},
          ${theme.colors.white.primary} 3px,
          transparent 3px,
          transparent 8px
        ),
      /* 右の線 */
        linear-gradient(
          to left,
          ${theme.colors.white.primary},
          ${theme.colors.white.primary} 3px,
          transparent 3px,
          transparent 8px
        ),
      /* 下の線 */
        linear-gradient(
          to top,
          ${theme.colors.white.primary},
          ${theme.colors.white.primary} 3px,
          transparent 3px,
          transparent 8px
        ); /* 左の線 */
    background-size: 8px 2px, /* 上の線 */ 2px 8px, /* 右の線 */ 8px 2px,
      /* 下の線 */ 2px 8px; /* 左の線 */
    background-position: left top, /* 上の線 */ right top,
      /* 右の線 */ right bottom, /* 下の線 */ left bottom; /* 左の線 */
    background-repeat: repeat-x, /* 上の線 */ repeat-y, /* 右の線 */ repeat-x,
      /* 下の線 */ repeat-y; /* 左の線 */
    padding: 5px;
  `}
`;

const Text = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.xl};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    line-height: 30px;
  `}
`;

export default Heading1;
