import { BLOCKS } from "@contentful/rich-text-types";
import {
  AccessTime,
  ArrowForwardIos as BaseArrowForwardIos,
} from "@mui/icons-material";
import { Button as BaseButton, Typography } from "@mui/material";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import styled, { css } from "styled-components";
import CompanyIndex from "../../components/companyIndex";
import { default as BaseContact } from "../../components/contact";
import Heading1 from "../../components/contentful/heading1";
import Heading2 from "../../components/contentful/heading2";
import Heading3 from "../../components/contentful/heading3";
import Heading4 from "../../components/contentful/heading4";
import Image from "../../components/contentful/image";
import Li from "../../components/contentful/li";
import Paragraph from "../../components/contentful/paragraph";
import CompanyIndexSP from "../../components/sp/companyIndexSP";
import Tag from "../../components/tag";
import { formatDate } from "../../helpers/date";
import topBackground from "../../images/background.png";
import { AREA_NAME } from "../../utils/Area";

const options = {
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <Image
          image={getImage(node.data.target)}
          alt={node.data.target.title}
        />
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <Paragraph>{children}</Paragraph>;
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <Heading1>{children}</Heading1>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <Heading2>{children}</Heading2>;
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <Heading3>{children}</Heading3>;
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <Heading4>{children}</Heading4>;
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <Li>{children}</Li>;
    },
  },
};
const Article = ({
  content,
  newServiceContents,
  similarityServiceContents,
}) => {
  const location = useLocation();
  const updatedAt = new Date(content.updatedAt);

  return (
    <>
      <MainDiv>
        <TempleteDiv>
          <ContentDiv>
            <FirstDiv>
              <Title component="h1">{content.title}</Title>
              <UnderLine />
              <TagDiv>
                {content.tags.map((tag) => {
                  return <Tag title={tag.title} />;
                })}
              </TagDiv>
              <Share>
                <TwitterShareButton
                  url={location.href}
                  title={`${content.title} | ro-tori (ロートリ)`}
                >
                  <TwitterIcon size={50} borderRadius={10} />
                </TwitterShareButton>
                <FacebookShareButton url={location.href} quote="テスト">
                  <FacebookIcon size={50} borderRadius={10} />
                </FacebookShareButton>
                <LineShareButton url={location.href} quote="テスト">
                  <LineIcon size={50} borderRadius={10} />
                </LineShareButton>
                <LinkedinShareButton url={location.href} quote="テスト">
                  <LinkedinIcon size={50} borderRadius={10} />
                </LinkedinShareButton>
              </Share>
              <UpdatedAtWrapper>
                <AccessTime />
                <UpdatedAt>{formatDate(updatedAt)}</UpdatedAt>
              </UpdatedAtWrapper>
              <ArticleDiv>
                {content.body && renderRichText(content.body, options)}
              </ArticleDiv>
            </FirstDiv>
            <UnderLine />
            <SimilarityServiceTitleDiv>
              <SimilarityServiceTitle>
                類似したビジネスモデル
              </SimilarityServiceTitle>
              <SimilarityServiceDiv>
                {similarityServiceContents.edges.map((c) => {
                  return <CompanyIndex key={c.node.id} {...c} />;
                })}
              </SimilarityServiceDiv>
            </SimilarityServiceTitleDiv>
            <UnderLine />

            <ButtonDiv>
              <ServiceButton
                id={"service-list-button-click"}
                gtm-service-list-redirect-button={`service-list-redirect-button`}
                onClick={() => navigate(`/area/?areaName=${AREA_NAME.JAPAN}`)}
              >
                ビジネスモデル一覧へ <ArrowForwardIos />
              </ServiceButton>
              <TopButton
                id={"top-page-redirect-button-click"}
                gtm-top-redirect-button={`top-redirect-button`}
                onClick={() => navigate(`/?areaName=${AREA_NAME.JAPAN}`)}
              >
                TOPページへ <ArrowForwardIos />
              </TopButton>
            </ButtonDiv>
          </ContentDiv>
          <SideDiv>
            <NewServiceTitle>新着記事</NewServiceTitle>
            <UnderLine />
            <NewServiceDiv>
              {newServiceContents.edges.map((content) => {
                return <CompanyIndexSP key={content.node.id} {...content} />;
              })}
            </NewServiceDiv>
          </SideDiv>
        </TempleteDiv>
        <ContactDiv>
          <Contact />
        </ContactDiv>
      </MainDiv>
    </>
  );
};

const MainDiv = styled.div`
  background-image: url(${topBackground});
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  text-align: center;
  width: 100vw;
`;

const TempleteDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px;
  gap: 40px;
`;

const FirstDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ContentDiv = styled.div`
  max-width: 700px;
`;

const TagDiv = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
`;

const SideDiv = styled.div`
  max-width: 415px;
`;

const UpdatedAtWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
`;

const UpdatedAt = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
  `}
`;

const SimilarityServiceDiv = styled.div`
  display: flex;
  gap: 30px;
`;

const NewServiceDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const ArrowForwardIos = styled(BaseArrowForwardIos)`
  ${({ theme }) => css`
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    line-height: 32px;
  `}
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 40px;
`;

const ServiceButton = styled(BaseButton)`
  ${({ theme }) => css`
    padding: 23px 22px;
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    background-color: ${theme.colors.yellow.primary};
    border-radius: 10px;
    min-width: 275px;
    height: 50px;

    &:hover {
      color: ${theme.colors.white.primary};
      background-color: ${theme.colors.yellow.primary};
    }
  `}
`;

const TopButton = styled(BaseButton)`
  ${({ theme }) => css`
    padding: 23px 22px;
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    background-color: ${theme.colors.purple.primary};
    border-radius: 10px;
    min-width: 275px;
    height: 50px;

    &:hover {
      color: ${theme.colors.white.primary};
      background-color: ${theme.colors.purple.primary};
    }
  `}
`;

const Share = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-top: 17px;
`;
const ArticleDiv = styled.div`
  ${({ theme }) => css`
    text-align: left;
    display: inline-block;
    padding: 32px;
    ${theme.typography};
  `}
`;

const Title = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xl};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    margin-bottom: 10px;
  `}
`;

const SimilarityServiceTitleDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 70px;
`;

const SimilarityServiceTitle = styled(Title)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xl};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    margin-bottom: 20px;
  `}
`;

const NewServiceTitle = styled(Title)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xl};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    margin-bottom: 10px;
  `}
`;

const UnderLine = styled.div`
  ${({ theme }) => css`
    border-top: 2px dashed ${theme.colors.gray.secondary};
    width: 100%;
  `}
`;

const Contact = styled(BaseContact)``;

const ContactDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding-bottom: 70px;
`;

export default Article;
